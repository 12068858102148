/* Desktop responsiveness (1025px and above) */
@media (min-width: 1025px) {
  :root {
    --container-max-width: 1200px;
    --font-size-small: 0.875rem;
    --font-size-normal: 1rem;
    --font-size-large: 1.25rem;
  }
}

/* Tablet responsiveness (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  :root {
    --container-max-width: 960px;
    --font-size-small: 0.85rem;
    --font-size-normal: 0.95rem;
    --font-size-large: 1.125rem;
  }

  .header-content {
    flex-direction: row;
    padding: var(--spacing-sm) 0;
  }

  .global-button {
    padding: var(--spacing-sm) var(--spacing-md);
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}

/* Mobile responsiveness (576px to 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  :root {
    --container-max-width: 720px;
    --font-size-small: 0.8rem;
    --font-size-normal: 0.9rem;
    --font-size-large: 1rem;
  }

  .header-content {
    flex-direction: column;
    padding: var(--spacing-sm) 0;
  }

  .global-button {
    padding: var(--spacing-xs) var(--spacing-sm);
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }
}

/* Extra-small devices (up to 575px) */
@media (max-width: 575px) {
  :root {
    --container-max-width: 100%;
    --font-size-small: 0.75rem;
    --font-size-normal: 0.85rem;
    --font-size-large: 0.95rem;
  }

  .header-content {
    flex-direction: column;
    padding: var(--spacing-xs) 0;
  }

  .global-button {
    padding: var(--spacing-xs);
    font-size: var(--font-size-small);
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.125rem;
  }
}

/* Add this to your existing global.css */

@media (max-width: 768px) {
  body.mobile-panel-open {
    overflow: hidden;
  }
}
